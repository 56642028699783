import { TextField } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { CHANGE_CLIENT_ADDRESS, CHANGE_CLIENT_NAME, CHANGE_CLIENT_NUMBER, TARGET_WS_INFO_POINTS_TARGET } from "../../reducers/types"
import { NavLink } from "react-router-dom"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import EditProfile from "../../queries/EditProfile"
import { useEffect, useState } from "react"
import CheckAdmin from "../../queries/CheckAdmin"

const UserInfo = () => {

    const user_info = useSelector(s => s.app.user_info)
    const dispatch = useDispatch()
    const [isUserAdmin, setIsUserAdmin] = useState(false)
    const [localName, setLocalName] = useState(user_info.name)
    const [localNumber, setLocalNumber] = useState(user_info.phone_number)
    const [localAddress, setLocalAddress] = useState(user_info.address)
    const [onSave, setOnSave] = useState(false)
    const [errorFromServer, setErrorFromServer] = useState(false)

    const editProfileHandler = () => {
      setErrorFromServer(false)
      EditProfile(localName, localNumber, localAddress, user_info.id).then((resp) => {
        setOnSave(resp.result)
        if(resp.result){
          dispatch({ type: CHANGE_CLIENT_NAME, payload: localName })
          dispatch({ type: CHANGE_CLIENT_NUMBER, payload: localNumber })
          dispatch({ type: CHANGE_CLIENT_ADDRESS, payload: localAddress })
          let timer = setTimeout(() => {
            setOnSave(false)
            clearTimeout(timer)
          }, 1000)
        }else{
          setErrorFromServer(resp.erorr)
        }
      })
    }


    useEffect(() => {
      let tg = window.Telegram.WebApp;
      if (tg.initDataUnsafe.user) { // ВНИМАНИЕ
        CheckAdmin(tg.initDataUnsafe.user.id).then(response => { // ВНИМАНИЕ 
          setIsUserAdmin(response.result)
        })
      }
    }, [])

    return <div className="user-info-container">
        <div className="heading-block">
            <h1>Ваш аккаунт</h1>
            {isUserAdmin && <NavLink className="admin-panel-button" to="/adm7730s">Админ панель</NavLink>}
            
          <div className="user-info-points-block">
              <h3>У вас {user_info.points} баллов</h3>
              <div className="info-button" onClick={() => dispatch({type: TARGET_WS_INFO_POINTS_TARGET})}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#444444">
                  <path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                </svg>
              </div>
          </div>
        </div>
        <TextField onChange={e => setLocalName(e.target.value)} label="Имя" value={localName} className="name" />
        <PhoneInput
            value={localNumber}
            onChange={e => setLocalNumber(e)}
            containerClass="phone-number"
            country='ru' />
        <TextField onChange={(e) => setLocalAddress(e.target.value)} type="text" id="street-address" name="street-address" autoComplete="street-address" label="Адрес" value={localAddress} className="address" />
        <p className="prompt">Например, Санкт-Петербург, ул. Короленко, д.8</p>
        {errorFromServer && <p className="error" style={{ textAlign: "center", margin: "0" }}>{errorFromServer}</p>}
        {onSave
            ? <div className="save_message">Изменения сохранены!</div>
            : <div onClick={editProfileHandler} className="save-but">Сохранить изменения</div>}
    </div>
}

export default UserInfo